import React from 'react';

function AddressForm() {
  return (
    <form>
      <h2>Address Form</h2>
      <label>
        Address 1: 
        <input type="text" name="address1" />
      </label>
      <button type="submit">Find my Hangout</button>
    </form>
  );
}

export default AddressForm;
